import React, { useEffect } from "react";
import {timeParse, scaleTime, scaleLinear, line, select, csv, extent, max, axisBottom, axisLeft} from "d3";
import "d3-time-format";

const parseTime = timeParse("%d-%b-%y");

const createGraph = async () => {
  const margin = { top: 20, right: 20, bottom: 50, left: 70 },
    width = 960 - margin.left - margin.right,
    height = 500 - margin.top - margin.bottom;
  const x = scaleTime().range([0, width]);
  const y = scaleLinear().range([height, 0]);

  const valueLine = line()
    .x((d) => { return x(d.date); })
    .y((d) => { return y(d.close); });

  const svg = select("body").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  let data = await csv(require("./data.csv"))

  data.forEach((d) => {
    d.date = parseTime(d.date);
    d.close = +d.close;
  });

  data = data.sort((a, b) => +a.date - +b.date)

  x.domain(extent(data, (d) => { return d.date; }));
  y.domain([0, max(data, (d) => { return d.close; })]);

  svg.append("path")
    .data([data])
    .attr("class", "line")
    .attr("d", valueLine);

  svg.append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(axisBottom(x));

  svg.append("g")
    .call(axisLeft(y));
}

export default function App() {
  useEffect(() => {
    createGraph();
  }, []);

  return (
    <div>
      <style>{
        `
          .line {
            fill: none;
            stroke: steelblue;
            stroke-width: 2px;
          }
      `}
      </style>
    </div>
  );
}