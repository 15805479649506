x,y
525,75.2
125,46.6
123,31.9
231,64.1
97,35.0
108,43.7
185,42.6
286,38.2
193,45.2
194,39.9
735,80.2
626,74.3
1392,98.4
920,94.4
222,48.7
104,37.8
114,51.5
77,44.0
81,31.2
205,34.2
199,38.2
364,59.9
755,77.5
178,43.5
141,49.7
258,83.6
881,95.7
547,77.7
133,64.8
93,37.2
56,37.0
67,24.2
189,46.7
280,64.5
136,49.2
73,32.7
96,32.6
134,52.9
70,43.5
510,72.4
82,31.4
133,48.0
175,47.8
114,47.2
107,46.1
160,40.2
145,67.8